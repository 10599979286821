<template>
    <b-card
            no-body
            class="card-statistics"
    >
        <b-card-body class="statistics-body">
            <b-row :class="{'flex-row-reverse':true}">
                <b-col
                        v-for="item in statisticsItems"
                        :key="item.icon"
                        md="2"
                        sm="6"
                        class="mb-2 mb-md-0"
                        :class="item.customClass"
                >
                    <b-media no-body>
                        <b-media-aside

                                class="mr-2"
                        >
                            <b-avatar
                                    size="48"
                                    :variant="item.color"
                            >
                                <feather-icon
                                        size="24"
                                        :icon="item.icon"
                                />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ item.title }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                {{ item.subtitle }}
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
                <b-col md="2"
                       sm="6"
                       class="mb-2 mb-md-0">
                    <b-media no-body>
                        <b-media-aside

                                class="mr-2"
                        >
                            <b-avatar
                                    size="48"
                                    variant="light-primary"
                            >
                                <feather-icon
                                        size="24"
                                        icon="AtSignIcon"
                                />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                 تراکنش
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                تعداد
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
    import {
        BCard,
        // BCardHeader,
        // BCardTitle,
        BCardText,
        BCardBody,
        BRow,
        BCol,
        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,
    } from 'bootstrap-vue'

    export default {
        name:'StatisticsGroup2',
        components: {
            BRow,
            BCol,
            BCard,
            // BCardHeader,
            // BCardTitle,
            BCardText,
            BCardBody,
            BMedia,
            BAvatar,
            BMediaAside,
            BMediaBody,
        },
        data() {
            return {
                statisticsItems: [
                    {
                        icon: 'TrendingUpIcon',
                        color: 'light-primary',
                        title: '23,000',
                        subtitle: 'ساعتی',
                        customClass: 'mb-2 mb-xl-0',
                    },
                    {
                        icon: 'UserIcon',
                        color: 'light-info',
                        title: '145k',
                        subtitle: 'روزانه',
                        customClass: 'mb-2 mb-xl-0',
                    },
                    {
                        icon: 'BoxIcon',
                        color: 'light-danger',
                        title: '12,423k',
                        subtitle: 'هفتگی',
                        customClass: 'mb-2 mb-sm-0',
                    },
                    {
                        icon: 'DollarSignIcon',
                        color: 'light-success',
                        title: '124m',
                        subtitle: 'ماهانه',
                        customClass: '',
                    },
                    {
                        icon: 'DollarSignIcon',
                        color: 'light-success',
                        title: '4,475m',
                        subtitle: 'سالانه',
                        customClass: '',
                    },
                ],
            }
        },
    }
</script>
