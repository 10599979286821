<template>
    <b-card>
        <h4>
            فیلتر نمودار تراکنش ها
        </h4>
        <b-button-group class="my-50 mr-75 " size="sm">
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="btn-icon"
                    variant="outline-primary"
            >
                <feather-icon icon="ChevronRightIcon"/>
            </b-button>
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="filterBy === 'day'?'primary':'outline-primary'" @click="filterBy='day'"
            >
                روزانه
            </b-button>
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="filterBy === 'month'?'primary':'outline-primary'" @click="filterBy='month'"
            >
                ماهانه
            </b-button>
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="filterBy === 'year'?'primary':'outline-primary'" @click="filterBy='year'"
            >
                سالانه
            </b-button>
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="btn-icon"
                    variant="outline-primary"
            >
                <feather-icon icon="ChevronLeftIcon"/>
            </b-button>
        </b-button-group>
        <!-- group 2 -->
        <b-button-group class="my-50" size="sm">
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="filterBy1 === 'toman'?'primary':'outline-primary'" @click="filterBy1='toman'"
            >
                رمزارز
            </b-button>
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="filterBy1 === 'crypto'?'primary':'outline-primary'" @click="filterBy1='crypto'"
            >
                تومان
            </b-button>
        </b-button-group>
        <h4 class="mt-75">
            تعداد تراکنش ها
        </h4>
        <div class="my-50">
            <p class="m-0">52 تراکنش</p>
            <p class="m-0"> 15 تراکنش برپایه تومان</p>
            <p class="m-0"> 37 تراکنش برپایه تتر</p>
        </div>

    </b-card>
</template>

<script>
    import {
        BCard,
        // BCardHeader,
        // BCardTitle,
        // BCardBody,
        // BCardText,
        BButton,
        BButtonGroup,
    } from 'bootstrap-vue'
    // import {$themeColors} from '@themeConfig'
    export default {
        name: "FilterChart2",
        components: {
            BCard,
            // BCardHeader,
            // BCardText,
            // BCardTitle,
            // BCardBody,
            BButton,
            BButtonGroup,
        },
        data() {
            return {
                filterBy: 'month',
                filterBy1: 'crypto',
            }
        }
    }
</script>

<style scoped>

</style>
